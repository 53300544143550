import React from 'react'
import { graphql } from 'gatsby';

import styled from 'styled-components';
import Cursor from '../components/Cursor';
import HeaderAndMenu from '../components/HeaderAndMenu';
import MyMarquee from '../components/Marquee';

import {device} from '../styles/Breakpoints'

import BlockContent from '@sanity/block-content-to-react';
import { Helmet } from "react-helmet";
import Footer from '../components/Footer';




export default function InfoPage({ data }) {
    // console.log('INFO PAGE', data)

    // const testJSON = JSON.parse(data.galleryData.nodes[2].credits[0]._rawChildren[0].text)

    // console.log('JSON', testJSON.test)
    const InfoStyles = styled.section`

            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100vh;
            @media ${device.$small} {
                max-width: 100%;
                padding: 12px;
              }
            .marquee {
              position: absolute !important;
            }
            .content-container {
              width: 50%;
              text-align: center;
              @media ${device.$small} {
                width: 100%;
              }
              h2 {
                font-size: 18px;
                font-weight: 900;
                margin: 0;
                @media ${device.$small} {
                  font-size: 24px;
                }
              }
              p {
                margin: 0;
              }
              .info-content {
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                margin-top: 45px;
                margin-bottom: 72px;
                max-width: 100%;
                a {
                  text-decoration: underline;
                  &:hover {
                    background: -webkit-linear-gradient(1turn, ${props => props.siteSettings.hover_gradient_one.hex}, ${props => props.siteSettings.hover_gradient_two.hex}, ${props => props.siteSettings.hover_gradient_three.hex});
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                  }
                }
              }
              .contact-content {
                font-size: 18px;
                font-weight: 500;
                margin-top: 16px;
                @media ${device.$small} {
                  font-size: 18px;
                }
                a {
                  &:hover {
                    background: -webkit-linear-gradient(1turn, ${props => props.siteSettings.hover_gradient_one.hex}, ${props => props.siteSettings.hover_gradient_two.hex}, ${props => props.siteSettings.hover_gradient_three.hex});
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                  }
                }
              }
            }
    `


    const allSectionData = data.homepageData.nodes[0].content;
    const allGalleryData = data.galleryData.nodes;
    const shopData = data.homepageData.nodes[0].content[2];
    const siteSettings = data.siteSettings.nodes[0]
    const roleData = data.allSanityRole.nodes

    const infoPageData = data.allSanityInfoPage.nodes[0];

    // console.log('INFO PAGE DATA', infoPageData)

    return (
      <>
        <Helmet>
          <title>Info | Imogen WIlson</title>
        </Helmet>
        <Cursor 
            staticText={data.siteSettings.nodes[0].cursor_static}
            staticTextColor={data.siteSettings.nodes[0].cursor_static_color.hex}
            cursorSparkle={data.siteSettings.nodes[0].cursor_sparkle}
          />
        <InfoStyles siteSettings={data.siteSettings.nodes[0]}>
          <HeaderAndMenu siteSettings={data.siteSettings.nodes[0]} roleData={roleData} page={'info'}/>
          <MyMarquee color={data.siteSettings.nodes[0].marquee_colour?.rgb} content={data.siteSettings.nodes[0].marquee_text}/>
            <div className="content-container">
            <h2>{infoPageData.info_title}</h2>
            <BlockContent 
              className="info-content"
              blocks={infoPageData._rawInfo} 
              renderContainerOnSingleChild={true}
            />
            <h2>{infoPageData.contact_title}</h2>
            <BlockContent 
              blocks={infoPageData._rawContactDetails} 
              renderContainerOnSingleChild={true}
              className="contact-content"
            />
            </div>
            {/* <pre>
              {data.allSanityInfoPage.nodes[0].info[0]._rawChildren}
            </pre> */}
        </InfoStyles>
        <Footer siteSettings={data.siteSettings.nodes[0]} />
      </>
    )
}

export const query = graphql`
query {
    homepageData: allSanityHomepage {
        nodes {
          content {
            ... on SanityIndexSectionSplash {
                enable_section
                desktop_image {
                    asset {
                        fluid(maxWidth: 1920) {
                            ...GatsbySanityImageFluid
                        }
                    }
                }
                desktop_video {
                  video_file {
                    asset {
                      url
                      mimeType
                    }
                  }
                }
                mobile_image {
                    asset {
                      fluid(maxWidth: 600) {
                        ...GatsbySanityImageFluid
                        }
                    }
                }
                splash_logo {
                    asset {
                        fluid(maxWidth: 800) {
                            ...GatsbySanityImageFluid
                        }
                    }
                }
              }
              ... on SanityIndexSectionShop {
                enable_section
                products {
                  product_id
                }
              }
              ... on SanityIndexSectionFeatured {
                enable_section
              }
              ... on SanityIndexSectionDiary {
                diary_json
                enable_section
              }
          }
          id
        }
    }
    galleryData: allSanityGallery {
        nodes {
          name
          role {
            name
          }
          images {
            asset {
              fluid(maxWidth: 800) {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            current
          }
          credits {
            _key
            _type
            style
            list
            _rawChildren
          }
          vimeo_url
        }
    }
    siteSettings: allSanitySiteSettings {
        nodes {
          logo {
            asset {
              fluid(maxWidth: 800) {
                ...GatsbySanityImageFluid
              }
              extension
              url
            }
          }
          menu_colour {
            hex
          }
          menu_text_colour {
            hex
          }
          marquee_colour {
            rgb {
              a
              b
              g
              r
            }
          }
          marquee_text
          menu_emoji_one
          menu_emoji_one_url
          menu_emoji_two
          menu_emoji_two_url
          menu_emoji_three
          menu_emoji_three_url
          cursor_static
          cursor_sparkle
          cursor_static_color {
            hex
          }
          mimi_casting_url
          instagram_url
          shop_url
          vimeo_url
          footer_mailto
          hover_gradient_one {
            hex
          }
          hover_gradient_two {
            hex
          }
          hover_gradient_three {
            hex
          }
        }
    }
    allSanityRole {
      nodes {
        name
        order
      }
    }
    allSanityInfoPage {
      nodes {
        _rawInfo
        _rawContactDetails
        info_title
        contact_title
      }
    }
}
  
`